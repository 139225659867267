@import "../css/constants.scss";

.barInput {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    margin-left: 0px;
    border-radius: 0px 10px 10px 0px;

    padding: 16px;
    margin: 5px 0px;

    width: 180px;
    text-align: left;
  }

  .iconWrap {
    padding: 14px 15px 15px 15px;
    background-color: $dark_accent;
    color: $white;
    border-radius: 10px 0px 0px 10px;
  }
}
