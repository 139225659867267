@font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Bold_Italic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Sansation";
    src: url("../fonts/Sansation_Light_Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  