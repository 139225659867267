@import "./constants.scss";

body {
  background-color: $rich_black;
  font-family: "Sansation";
  color: $white;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-image: url("../images//living-wall.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.login {
  background-color: transparentize($rich_black, 0.1);
  border-radius: 10px;
  padding: 10px 40px;
  text-align: center;
}

button {
  border: solid thin transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: $white;
  color: $dark_accent;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover.not:disabled {
    background-color: $dark_accent;
    color: $white;
    border-color: $white;
  }

  &:disabled {
    background-color: grey;
  }
}
